
/* for modle Footer */

#wrapper{
    position: relative;
    top: 0;
    left: 0;
}

#one{
    position: relative;
        top: 0;
        left: 0;
        height: 60px;
        /* border: 1px solid #000000; */
}
#two{
    position: absolute;
        top: 10px;
        left: 30%;
        /* height: 20px; */
        width: 30px;
        /* border: 1px solid #000000; */
}

/* for inside modal image */

#modalInner{
    position: relative;
    top: 0;
    left: 0;
}
#modalInsideImg{
    position: absolute;
width: 150px;
height: 100px;
left: 100px;
top: 50px;
}